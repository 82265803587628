<template>
  <section>
    <b-navbar class="is-nav" :shadow=true :mobile-burger=false>
        <template #brand>
            <b-navbar-item tag="div" v-if="disp_menu_contents"><b-button @click="sidebar_open=true" icon-left="menu" type="is-light"></b-button></b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
              <img src="/static/img/SIPSx3_Logo.png" alt="SIPSx3">&nbsp;&nbsp;<span v-if="$debug === 'true'" class="has-text-danger">【検証環境】</span>
            </b-navbar-item>
        </template>
        <template #start v-if="disp_menu_contents">
            <b-navbar-item tag="router-link" :to="{ path: '/bridge' }" @click.native="$emit('clearSubTitle')" v-bind:class="[$route.path.match(/^\/bridge/) ? 'has-text-primary': 'has-text-primary-light']">
              <b-tooltip label="橋梁" position="is-bottom">
                &nbsp;<b-icon icon="bridge" size="is-medium"></b-icon>&nbsp;
              </b-tooltip>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/train' }" @click.native="$emit('clearSubTitle')" v-bind:class="[$route.path.match(/^\/train/) ? 'has-text-primary': 'has-text-primary-light']">
              <b-tooltip label="鉄道" position="is-bottom">
                &nbsp;<b-icon icon="train" size="is-medium"></b-icon>&nbsp;
              </b-tooltip>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/crane' }" @click.native="$emit('clearSubTitle')" v-bind:class="[$route.path.match(/^\/crane/) ? 'has-text-primary': 'has-text-primary-light']">
              <b-tooltip label="クレーン" position="is-bottom">
                &nbsp;<b-icon icon="crane" size="is-medium"></b-icon>&nbsp;
              </b-tooltip>
            </b-navbar-item>
            <b-navbar-item tag="div" class="has-text-grey-lighter">
              <b-tooltip label="止水" position="is-bottom">
                &nbsp;<b-icon icon="water-off-outline" size="is-medium"></b-icon>&nbsp;
              </b-tooltip>
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ path: '/njss' }" @click.native="$emit('clearSubTitle')" v-bind:class="[$route.path.match(/^\/njss/) ? 'has-text-primary': 'has-text-primary-light']">
              <b-tooltip label="NJSS" position="is-bottom">
                &nbsp;<b-icon icon="download-circle-outline" size="is-medium"></b-icon>&nbsp;
              </b-tooltip>
            </b-navbar-item>
        </template>
        <template #end>
            <b-navbar-dropdown label="" right>
                <b-navbar-item tag="router-link" to="/">
                    <b-icon icon="home"></b-icon>&nbsp;&nbsp;Home
                </b-navbar-item>
                <b-navbar-item  @click.native="logout">
                    <b-icon icon="logout"></b-icon>&nbsp;&nbsp;Logout
                </b-navbar-item>
                <hr class="navbar-divider">
                <b-navbar-item>
                    Version : {{$version}}
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>

    <b-sidebar type="is-light" :fullheight="fullheight" v-model="sidebar_open">
      <div class="pad-1">
        <b-menu>
          <b-menu-list label="User">
            <b-menu-item :label="disp_name" icon="account" size="is-medium"></b-menu-item>
          </b-menu-list>
          <b-menu-list label="Menu">
            <b-menu-item label="橋梁" icon="bridge" size="is-medium" tag="router-link" to="/bridge"></b-menu-item>
            <b-menu-item label="鉄道" icon="train" size="is-medium" tag="router-link" to="/train"></b-menu-item>
            <b-menu-item label="クレーン" icon="crane" size="is-medium" tag="router-link" to="/crane"></b-menu-item>
            <b-menu-item label="止水" icon="water-off-outline" size="is-medium" tag="router-link" to="/" disabled></b-menu-item>
            <b-menu-item label="NJSS" icon="download-circle-outline" size="is-medium" tag="router-link" to="/njss"></b-menu-item>
          </b-menu-list>
          <b-menu-list label="Actions">
            <b-menu-item label="Logout" icon="logout" size="is-medium" @click.native="logout"></b-menu-item>
          </b-menu-list>
          <b-menu-list label="version">
            <b-menu-item :label=$version disabled></b-menu-item>
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import Auth from '@/mixins/auth'

export default {
  props: {
    disp_menu_contents: {
      required: true,
    },
    user_info: {
    },
  },
  data() {
    return {
      sidebar_open: false,
      fullheight: true,
      disp_name: null,
    }
  },
  mixins: [
    Auth,
  ],
  created() {
    if (this.user_info){
      this.setDispName()
    }
  },
  watch: {
    user_info: function() {
      this.setDispName()
   },
  },
  methods: {
    setDispName() {
      this.disp_name = this.user_info.staff_name
   },
  },
}
</script>

<style>
@media screen and (min-width: 1024px) {
  .navbar-start {
      justify-content: flex-end;
      margin-left: auto;
  }
}
.pad-1 {
  padding: 1em !important;
}
li > a > span:last-child {
  vertical-align: top;
}
</style>
